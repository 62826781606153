<template>
  <div class="scan">
    <header>
      <p @click="goHome">返回</p>
      <span>请扫码二维码</span>
      <p @click="scanImg">相册</p>
      <!-- <button @click="startRecognize">扫码</button> -->
    </header>
    <div id="bcid">
      <div class="bcid_div"></div>
      <div class="tip">……扫码载入中……</div>
    </div>
  </div>
</template>

<script>
import bus from '@/bus'
/**
 * h5+ 扫码功能实现
 */
let scan = null
export default {
  name: 'Scan',
  data() {
    return {
      fromRouter: '',//进入扫码页面的上一个路由
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例，记录上一个页面的路由，
      vm.fromRouter = from.fullPath
    })
  },
  mounted() {
    var plusReady = function (callback) {
      if (window.plus) {

        callback();
        this.startRecognize()
      } else {
        document.addEventListener('plusready', callback);
      }
    };
  },
  beforeDestroy() {
    this.closeScan();
    bus.$emit('onScan', false)
  },

  methods: {
    goHome() {
      bus.$emit('onScan', false)
      // if (scan) {
      //   this.closeScan();
      // }

    },
    // 创建扫描控件
    startRecognize() {
      let that = this
      if (!window.plus) return
      scan = new window.plus.barcode.Barcode('bcid')
      scan.onmarked = onmarked
      that.startScan()
      function onmarked(type, result, file) {
        switch (type) {
          case window.plus.barcode.QR:
            type = 'QR'
            break
          case window.plus.barcode.EAN13:
            type = 'EAN13'
            break
          case window.plus.barcode.EAN8:
            type = 'EAN8'
            break
          default:
            type = '其它' + type
            break
        }
        // 获得code
        result = result.replace(/\n/g, '')
        if (result) {
          alert(result)
          console.log("re", result)
          //window.location.href = result.replace("192.168.1.97:8081", "192.168.1.114:8080")
          window.location.href = "http://192.168.1.114:8081/#/activeDetailTwo?id=23491&title=测试"
        } else {
          // 失败，关闭控件，重新扫描
          that.myUtils.showToast(that, '二维码识别失败，请重试');
          that.$router.replace(that.fromRouter)
        }
        that.closeScan()
        bus.$emit('onScan', false)
      }
    },
    // 开始扫描
    startScan() {
      if (!window.plus) return
      scan.start()
    },
    // 关闭扫描
    cancelScan() {
      if (!window.plus) return
      scan.cancel()
    },
    // 关闭条码识别控件
    closeScan() {
      if (!window.plus) return
      scan.close()
    },
    scanImg() {
      // 从系统相册选择文件
      if (!window.plus) return;
      window.plus.gallery.pick(
        function (path) {
          // alert(path);
          window.plus.barcode.scan(
            path,
            function (type, result) {
              alert("Scan success:(" + type + ")" + result);
              //window.localStorage.codeUrl2 = result;
            },
            function (e) {
              console.log(e);
              window.localStorage.codeUrl2 = e;
              window.plus.nativeUI.alert("如果图片无法识别,请用扫码上传");
            }
          );
        },
        function (e) {
          alert("取消选择图片");
        },
        { filter: "image" }
      );
    }
  },
}
</script>
<style lang="less">
.scan {
  height: 100%;
  #bcid {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0rem;
    bottom: 0;
    text-align: center;
    color: #fff;
    background: #ccc;
    .bcid_div {
      height: 40%;
      display: flex;
    }
  }
  header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    font-size: 16rpx;
    color: #009de2;
    left: 0.3rem;
    top: 0;
    right: 0.3rem;
    height: 5rem;
    line-height: 2rem;
    z-index: 2;
    span {
      color: #000;
    }
  }
}
</style>

