<template>
  <div class="log_con outerFrame">
    <!-- 未登录 -->
    <div class="log_hea" v-if="!personalInformation" @click="$router.push('/login')">
      <div class="log_lft">
        <img src="../../assets/user/loginhead.png" alt />
        <div class="top_fot">
          <p>登录</p>
          <span>登录后您可享受更多特权</span>
        </div>
      </div>
      <!-- <div class="log_rgt" @click.stop="$router.push('/register')">
        <img src="../../assets/user/zhuce.png" alt="" />
        <span>注册</span>
      </div>-->
    </div>
    <!-- 已登录 -->
    <div class="log_hea" v-else @click="$router.push('/user')">
      <div class="log_lft">
        <img v-if="personalInformation.avatar" :src="personalInformation.avatar" alt />
        <img v-else src="../../assets/user/loginhead.png" alt />
        <div class="top_fot">
          <p style="font-size: 17px">{{ personalInformation.nickname || personalInformation.name }}</p>
          <div v-if="personalInformation.pic" class="qiyeIn">
            <span>{{ personalInformation.regionName }}</span>
            <span>{{ personalInformation.serverName }}</span>
          </div>
          <!-- <div>
            <img
              class="log_xin"
              v-for="(item, index) in personalInformation.starNum"
              :key="index"
              src="../../assets/user/xing.png"
              alt
            />
          </div>-->
        </div>
      </div>
      <div v-if="!personalInformation.pic">
        <!-- <img
          v-if="codeShow"
          style="margin-right: 20px"
          @click.stop="goQr"
          src="../../assets/codePic.png"
          width="30px"
          alt
          srcset
        />-->
        <img src="../../assets/user/qiandao.png" class="log_rgt" @click.stop="onSignIn" alt />
      </div>
    </div>

    <div class="log_jif">
      <div class="jf_tit">积分时长</div>
      <div class="log_tjf" @click="enterDetail('/myScore')">
        <div class="jf_img">
          <img src="../../assets/user/jf.png" alt />
          <div class="jf_spa">
            <p>我的积分</p>
            <span>签到赚积分，天天兑好礼</span>
          </div>
        </div>
        <div class="jf_btn">
          <img src="../../assets/user/ck.png" alt />
        </div>
      </div>

      <div class="log_tjf" @click="enterDetail('/myTime')">
        <div class="jf_img">
          <img src="../../assets/user/zysc.png" alt />
          <div class="jf_spa">
            <p>志愿时长</p>
            <span>参加志愿活动，领取志愿时长</span>
          </div>
        </div>
        <div class="jf_btn">
          <img src="../../assets/user/ck.png" alt />
        </div>
      </div>

      <div class="log_tjf" @click="enterDetail('/cardPage')">
        <div class="jf_img">
          <img src="../../assets/images/zyzLogo.png" alt />
          <div class="jf_spa">
            <p>志愿者证</p>
            <span>个人信息填写完整，获得志愿者证</span>
          </div>
        </div>
        <div class="jf_btn">
          <img src="../../assets/user/ck.png" alt />
        </div>
      </div>
    </div>
    <div class="log_tem">
      <img src="../../assets/user/mytd1.png" alt @click="enterDetail('/myteam')" />
      <img src="../../assets/user/myhd1.png" alt @click="enterDetail('/myactivity')" />
      <img src="../../assets/user/myxy.png" alt @click="enterDetail('/mywish')" />
    </div>
    <div class="log_btm">
      <div class="jf_tit">我的工具</div>
      <div class="btm_col">
        <div class="col_itm" @click="enterDetail('/modify')">
          <img src="../../assets/user/xgmm.png" alt />
          <span>修改密码</span>
        </div>
        <!-- <div class="col_itm" @click="enterDetail('/mybook')">
          <img src="../../assets/user/wdzs.png" alt />
          <span>我的证书</span>
        </div>-->
        <!------------------ 双向打卡（扫描活动二维码进入 活动打卡页面 若非志愿者则跳入注册页面）----------->
        <div class="col_itm" @click="scanFun">
          <img src="../../assets/scanIcon1.png" alt />
          <span>扫一扫</span>
        </div>
        <!------------------ 双向打卡（扫描活动二维码进入 活动打卡页面 若非志愿者则跳入注册页面）----------->
        <!-- <div class="col_itm">
          <img src="../../assets/user/ysxy.png" alt="" />
          <span>隐私协议</span>
        </div>-->
      </div>
    </div>
    <scan v-show="scanQr" ref="scan"></scan>
  </div>
</template>

<script>
import authApi from "@/api/auth";
import PersonApi from "@/api/person";
import bus from "@/bus";
import { Toast } from "vant";
import scan from "../emptyPage";

export default {
  data() {
    return {
      isVolunteer: "",
      isLogin: false,
      codeShow: "",
      personalInformation: null,
      scanQr: false,
    };
  },
  components: {
    scan,
  },
  created() {

    window.uni.postMessage({
      data: {
        action: "message",
      },
    });
  },
  mounted() {

    this.$nextTick(() => {
      // 初始化uni.webview
      document.addEventListener("UniAppJSBridgeReady", function () {
        console.log("初始化uniapp的API成功");
        /* eslint-disable*/
        uni.getEnv(function (res) {
          console.log("当前环境：" + JSON.stringify(res));
        });
      });
    });
    bus.$on("onScan", (val) => {
      this.scanQr = val;
    });
    if (window.localStorage.userinfo) {
      this.getVolunteer();
    }
    console.log("user");
    if (
      window.localStorage.enterpriseInfo &&
      window.localStorage.enterpriseInfo != "undefined"
    ) {
      // 爱心企业登入
      this.personalInformation = JSON.parse(window.localStorage.enterpriseInfo);
    } else if (
      window.localStorage.personalInformation &&
      window.localStorage.personalInformation != "undefined"
    ) {
      // this.MemberInfoData();
      // console.log(window.localStorage.personalInformation);
      this.personalInformation = JSON.parse(
        window.localStorage.personalInformation
      );
    } else {
      this.personalInformation = null;
    }
  },

  beforeDestroy() {
    if (
      window.localStorage.personalInformation &&
      window.localStorage.personalInformation != "undefined"
    ) {
      // console.log(window.localStorage.personalInformation);
      this.MemberInfoData();
      this.personalInformation = JSON.parse(
        window.localStorage.personalInformation
      );
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      Toast('我监听成功了！')
    },
    goQr() {
      this.$router.push("/UserQRCode");
    },
    toLocationScan() {
      //注意！！！！！！！！！！！文明实践双向打卡 需要再此添加 appname
      if (this.appName == "今日和县" || this.appName == "爱旌德" || this.appName == "智慧新洲") {
        //如果codeshow为true是志愿者则跳入扫码页面 非志愿者则跳入注入注册页面
        if (this.codeShow) {//嵌套到uniapp
          window.uni.navigateTo({ url: '/pages/home/scanPage/scanPage' })
        } else {
          window.uni.navigateTo({ url: '/pages/home/scanPage/scanPage?notUser=' + true })
          // this.$router.push('/signUser')
        }
      } else {//嵌套到安卓中
        if (this.codeShow) {
          this.isVolunteer = 1;
        } else {
          this.isVolunteer = 0;
        }

        var msg = { "scanType": 1, isVolunteer: this.isVolunteer };
        var u = navigator.userAgent;
        var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
        if (isAndroid) {
          //安卓
          window.javaInterface.toScanQR(JSON.stringify(msg));
        } else {
          //苹果
          this.setupWebViewJavascriptBridge(function (bridge) {
            bridge.registerHandler(
              "toScanQR",
              function (data, responseCallback) {
                //注册
                var responseData = { "Javascript Says": "Right back atcha!" };
                responseCallback(responseData);
              }
            );
            bridge.callHandler("toScanQR", msg, function (response) {
              console.log(response);
            });
          });
        }
      }
    },
    getVolunteer() {
      var memId = JSON.parse(window.localStorage.userinfo).id;
      PersonApi.getVolunteer({ id: memId }).then((res) => {
        if (res.success) {
          this.codeShow = res.data.type;
        }
      });
    },
    //扫一扫
    scanFun() {
      if (this.isToken()) {
        // 没用登入调到登入
        this.toLocationScan();
      } else {
        return;
      }
    },
    // 根据token值获取会员信息
    MemberInfoData() {
      authApi
        .memberInfomatin()
        .then((res) => {
          var merberIn = JSON.parse(window.localStorage.userinfo);
          if (res.success) {
            const data = JSON.stringify(res.data);
            merberIn.id = res.data.id;
            window.localStorage.userinfo = JSON.stringify(merberIn); // 会员的token
            window.localStorage.personalInformation = data;
            window.localStorage.enterpriseInfo = ""; // 企业信息数据清空
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    enterDetail(url) {
      console.log(url);
      if (this.isToken()) {
        if (url === '/cardPage') {
          console.log("11");
          this.$router.push({ name: 'cardPage', query: { codeShow: this.codeShow } });
        } else {
          this.$router.push(url)
        }
      }
    },
    // 点击签到
    onSignIn() {
      const data = {
        code: 1,
        memberId: this.personalInformation.id,
      };
      PersonApi.addSignIn(data).then((res) => {
        if (res.success) {
          Toast.success("签到成功");
        } else {
          Toast(res.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 415px) {
  .log_con {
    background-color: #f8f8f8;
    padding: 10px;
    .log_hea {
      padding: 10px 0 30px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .log_lft {
        display: flex;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .log_xin {
          width: 12px;
          height: 12px;
        }
        .top_fot {
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .qiyeIn {
            display: flex;
            font-size: 12px;
            span {
              &:first-child {
                color: #1377e2;
              }
              &:last-child {
                color: #1377e2;
                margin-left: 10px;
              }
            }
          }
          p {
            font-weight: bold;
            font-size: 16px;
          }
          img {
            margin-right: 3px;
          }
        }
      }
      .log_rgt {
        width: 70px;
        height: auto;
        // padding: 1px 7px;
        // display: flex;
        // align-items: center;
        // border: 1px solid #1377e2;
        // color: #1377e2;
        // font-weight: bold;
        // font-size: 14px;
        // border-radius: 3px;
        // img {
        //   width: 19px;
        // }
      }
    }

    .log_jif {
      // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      background: linear-gradient(0deg, #f2f7fd, #e2f2fd, #e2f2fd);
      border-radius: 8px;
      padding: 10px 20px;
      margin-bottom: 10px;
      .log_tjf {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0px;
        .jf_img {
          display: flex;
          align-items: center;
          .jf_spa {
            margin-left: 6px;
            display: flex;
            flex-direction: column;
            p {
              font-weight: bold;
            }
            span {
              color: #a5a5a5;
              font-size: 11px;
            }
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
        .jf_btn {
          display: flex;
          align-items: center;
          img {
            width: 60px;
          }
        }
      }
    }
    .log_tem {
      display: flex;
      justify-content: flex-start;
      img {
        width: 30vw;
        height: 21vw;
        object-fit: contain;
        margin-right: 2.5vw;
        &:last-child {
          margin-right: 0vw;
        }
      }
    }
    .log_btm {
      background: #fff;
      margin-top: 10px;
      padding: 10px;
      border-radius: 5px;
      // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      .jf_tit {
        padding: 0px 10px;
      }
      .btm_col {
        margin-top: 10px;
        border-top: 1px solid #ececec;
        display: flex;
        justify-content: flex-start;
        padding: 0px 10px;
        padding-top: 10px;
        .col_itm {
          padding: 5px 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 40px;
          img {
            width: 25px;
          }
        }
      }
    }
    .jf_tit {
      font-weight: bold;
      font-size: 17px;
      height: 25px;
      line-height: 25px;
    }
  }
}

@media screen and (max-width: 414px) {
  .log_con {
    background-color: #f8f8f8;
    padding: 10px;
    .log_hea {
      padding: 10px 0 30px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .log_lft {
        display: flex;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .log_xin {
          width: 12px;
          height: 12px;
        }
        .top_fot {
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .qiyeIn {
            display: flex;
            font-size: 12px;
            span {
              &:first-child {
                color: #1377e2;
              }
              &:last-child {
                color: #1377e2;
                margin-left: 10px;
              }
            }
          }
          p {
            font-weight: bold;
            font-size: 20px;
          }
          img {
            margin-right: 3px;
          }
        }
      }
      .log_rgt {
        width: 70px;
        height: auto;
        // padding: 1px 7px;
        // display: flex;
        // align-items: center;
        // border: 1px solid #1377e2;
        // color: #1377e2;
        // font-weight: bold;
        // font-size: 14px;
        // border-radius: 3px;
        // img {
        //   width: 19px;
        // }
      }
    }

    .log_jif {
      // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      background: linear-gradient(0deg, #f2f7fd, #e2f2fd, #e2f2fd);
      border-radius: 8px;
      padding: 10px 20px;
      margin-bottom: 10px;
      .log_tjf {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0px;
        .jf_img {
          display: flex;
          align-items: center;
          .jf_spa {
            margin-left: 6px;
            display: flex;
            flex-direction: column;
            p {
              font-weight: bold;
              font-size: 16px;
            }
            span {
              color: #a5a5a5;
              font-size: 12px;
            }
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
        .jf_btn {
          display: flex;
          align-items: center;
          img {
            width: 60px;
          }
        }
      }
    }
    .log_tem {
      display: flex;
      justify-content: flex-start;
      img {
        width: 30vw;
        height: 21vw;
        object-fit: contain;
        margin-right: 2.5vw;
        &:last-child {
          margin-right: 0vw;
        }
      }
    }
    .log_btm {
      background: #fff;
      margin-top: 10px;
      padding: 10px;
      border-radius: 5px;
      // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      .jf_tit {
        padding: 0px 10px;
      }
      .btm_col {
        margin-top: 10px;
        border-top: 1px solid #ececec;
        display: flex;
        justify-content: flex-start;
        padding: 0px 10px;
        padding-top: 10px;
        .col_itm {
          padding: 5px 0px;
          display: flex;
          font-size: 14px;
          flex-direction: column;
          align-items: center;
          margin-right: 40px;
          img {
            width: 35px;
          }
        }
      }
    }
    .jf_tit {
      font-weight: bold;
      font-size: 16px;
      height: 25px;
      line-height: 25px;
    }
  }
}
</style>
